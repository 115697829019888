export const GENDER = {
  FEMALE: "Nữ",
  MALE: "Nam",
};

export const RECEIVE_TYPE = {
  LIVE: "LIVE",
  INSTANCE: "INSTANCE",
};

export const REGULATION_FORM_VI = `
<div class="text-center font-bold text-lg">QUY ĐỊNH THAM GIA</div>
<div>
    <div class="font-bold">I. NỘI DUNG LỘ TRÌNH THI ĐẤU</div>
    <ol class="list-decimal pl-5 py-2">
        <li>Nội dung thi đấu</li>
        <div>Thi đấu cá nhân ở 04 nội dung (cự ly): 5km, 10km, 21.1km và 42.195km.</div>

        <li>Thể thức thi đấu</li>
        <div>Tất cả vận động viên tập trung và xuất phát cùng lúc theo từng nội dung, và phải hoàn thành phần thi của mình trong thời gian giới hạn ứng với cự ly đã tham gia.</div>

        <li>Cách thức tính giải</li>
        <div>
            <ul class="list-disc pl-5 py-2">
                <li>Chung cuộc: Nhất, nhì, ba cả nam và nữ các cự ly.</li>
                <li>Nội dung 42,195km vận động viên nào đoạt giải trung cuộc thì sẽ không được nhận giải nhóm tuổi.</li>
                <li>Nội dung 42,195km có giải cho 4 nhóm tuổi tuổi</li>
                <li>Dưới 30 nhất, nhì, ba nam và nữ</li>
                <li>Từ 31 - 39 nhất, nhì, ba nam và nữ</li>
                <li>Từ 40 - 49 nhât, nhì, ba nam và nữ</li>
                <li>Từ 50 trở lên nhất, nhì, ba cho nam và nữ (nhất nhóm tuổi sẽ được nhân thêm đôi giầy NIKE Pegasus 40 của nhà tài trợ thịnh TaKuDa)</li>
            </ul>
        </div>
    </ol>


    <div class="font-bold">II. QUY ĐỊNH CHUNG</div>
    <ol class="list-decimal pl-5 py-2">
        <li>Vận Động Viên phải tuân thủ quy định, hướng dẫn và sự điều phối của Ban Tổ chức, Tình Nguyện Viên, Nhân viên Y tế, Cảnh sát giao thông. Phải tôn trọng và hoà nhã với những người cùng tham gia, thành viên Ban Tổ Chức, và khán giả trước, trong và sau cuộc đu.</li>
        <li>Nhân viên y tế do Ban tổ chức chỉ định có thể kiểm tra bất kỳ vận động viên nào có dấu hiệu sức khỏe không ổn. Trong trường hợp nhân viên y tế quyết định vận động viên không nên tiếp tục cuộc đua, Ban tổ chức có quyền đưa vận động viên ra khỏi khu vực sự kiện.</li>
        <li>Vận động viên có trách nhiệm và nghĩa vụ thanh toán cho tất cả và bất kỳ khoản phí y tế phát sinh do việc luyện tập và/hay tham gia sự kiện, bao gồm cả phương tiện xe cứu thương, thời gian nằm viện, bác sĩ, dược phẩm và dịch vụ.</li>
        <li>Vận động viên cần liên hệ các số điện thoại khẩn cấp trên BIB khi gặp vấn đề trên đường chạy và cần đi tới điểm tiếp nước gần nhất để được hỗ trợ khi muốn dừng cuộc đua.</li>
        <li>Việc sử dụng từ ngữ dơ bẩn, thô tục, cãi nhau hoặc các hành vi phi thể thao đối với những Vận Động Viên khác, điều phối viên cuộc đua, nhân viên y tế, người thực thi giao thông, đội ngũ tình nguyện viên, thành viên Ban Tổ Chức, hoặc khán giả là không được cho phép trước, trong hoặc sau cuộc đua và sẽ dẫn đến việc bị truất quyền thi đấu.</li>
        <li>Việc bảo vệ môi trường là một phần quan trọng của cuộc đua, nghiêm cấm các hành vi xả rác, và phá hoại môi trường trong suốt quá trình thi đấu. Vi phạm sẽ bị truất quyền thi đấu.</li>
        <li>Vận Động Viên không được phép sử dụng các chất kích thích, các loại ma túy hoặc các chất làm tăng cảm giác hưng phấn khác trong cuộc đua.</li>
        <li>VĐV được cấp số đeo: Phải đeo số trước ngực trước khi xuất phát tạo điều kiện tốt nhất cho ban tổ chức và máy móc thực hiện nhiệm vụ.</li>
        <li>Các VĐV tham gia giải thực hiện tốt các quy định về điều lệ giải và luật điền kinh Việt Nam.</li>
        <li>Trong quá trình thi đấu không được nhờ sự trợ giúp của cá nhân hay tổ chức bên ngoài nếu bị phát hiện kết quả thi đấu sẽ không được công nhận.</li>
        <li>Quá trình chạy không chen lấn, xô đẩy, giẫm đạp lên nhau, vận động viên không được sử dụng số đeo của người khác để thi đấu nếu bị phát hiện sẽ hủy kết quả và cấm thi đấu các năm tiếp theo.</li>
    </ol>


    <div class="font-bold">III. ĐĂNG KÝ THI ĐẤU VÀ LỆ PHÍ</div>
    <ol class="list-decimal pl-5 py-2">
        <li>Địa điểm đăng ký</li>
        <li>Thời gian đăng ký</li>
        <li>Thời gian nhận BIB (Ngày 08 tháng 06 năm 2024)</li>
        <li>Lệ phí đăng ký (Cập nhật trên cổng đăng ký)</li>
        <li>Tuổi đăng ký</li>
        <div>
            <ul class="list-disc pl-5 py-2">
                <li>Tất cả vận động viên tham gia cự ly 42KM: Phải từ 16 tuổi trở lên tính đến ngày tham dự giải.</li>
                <li>Tất cả vận động viên tham gia cự ly 21KM: Phải từ 14 tuổi trở lên tính đến ngày tham dự giải.</li>
                <li>Tất cả vận động viên tham gia cự ly 10KM: Phải từ 10 tuổi trở lên tính đến ngày tham dự giải.</li>
                <li>Tất cả vận động viên tham gia cự ly 5KM: Phải từ 03 tuổi trở lên tính đến ngày tham dự giải.</li>
                <li>Tất cả các VĐV từ 10 tuổi trở xuống tham gia cự ly 5km nên có người giám hộ chạy cùng.</li>
            </ul>
        </div>

    </ol>
    
    
    <div class="font-bold">IV. KHEN THƯỞNG - KỶ LUẬT</div>
    <ol class="list-decimal pl-5 py-2">
        <li>Khen Thưởng</li>
        <div>VĐV được giải phần thưởng bằng hiện kim và quà tặng</div>
        <div>Chung cuộc: Nhất, nhì, ba cả nam và nữ các cự ly.</div>
        <ul class="list-disc pl-5 py-2">
            <li>Nhóm tuổi dưới 30 tuổi nhất, nhì, ba</li>
            <li>Nhóm tuổi từ 30 – 39</li>
            <li>Nhóm tuổi 40- 49</li>
            <li>Nhóm tuổi 50+ nhất nam và nữ được thưởng thêm đôi giày nhà tài trợ</li>
        </ul>
        <li>Kỷ Luật</li>
        <div>Các VĐV vi phạm các quy định của luật, điều lệ thì tùy vào mức độ mà ban tổ chức sẽ có các hình thức kỷ luật phù hợp cao nhất là hủy kết quả thi đấu và cấm thi đấu các năm tiếp theo.</div>
        <li>Khiếu Nại</li>
        <div>Các VĐV có quyền khiếu nại bằng văn bản đến ban tổ chức và chịu trách nhiệm về nội dung khiếu nại đó. Khiếu nại về nhân sự phải trước khi thi đấu, khiếu nại về chuyên môn chậm nhất 10 phút sau khi công bố kết quả thi đấu của ban trọng tài.</div>
        <li>Quy định về kết quả thi đấu và xếp hạng trao thưởng</li>
        <ul class="list-disc pl-5 py-2">
            <li>Thẻ đeo thứ hạng tạm thời trao cho các vận động viên tại vạch đích chỉ mang tính chất xác định các thứ hạng đầu kết thúc đường chạy theo thực tế. Sau đó Ban tổ chức cùng tổ trọng tài sẽ đối chiếu các thông tin cần thiết kèm camera tại vạch về đích để có thể tổng hợp kết quả chính xác nhất lần cuối cùng trước khi công bố tại lễ trao giải.</li>
            <li>Kết quả thi đấu của từng vận động viên được ghi nhận bởi thiết bị đo thời gian (chiptime) gắn trên BIB được Ban tổ chức cung cấp. Chiptime được tính từ thời điểm vận động viên vượt qua thảm chip tại vạch xuất phát và kết thúc tại vạch đích.</li>
            <li>Gun time (thời gian được tính ngay khi có hiệu lệnh xuất phát đến khi vận động viên chạy qua thảm chip tại vạch đích) sẽ được sử dụng để xếp hạng trao thưởng, là thứ tự về đích thực tế của vận động viên đối với nội dung đăng ký thi đấu.</li>
            <li>Vận động viên sẽ không được công nhận hoàn thành nếu vượt quá thời gian chạy ở các mốc cut-off time theo công bố của Ban tổ chức.</li>
            <li>Ban tổ chức sẽ loại, hủy kết quả thi đấu nếu phát hiện vận động viên gian lận.</li>
            <li>Ban tổ chức toàn quyền quyết định về kết quả xếp hạng trao thưởng và kết quả thi đấu. Quyết định của Ban tổ chức là quyết định cuối cùng. Mọi khiếu nại về giải nhất, nhì, ba được thực hiện trong vòng 3 tiếng kể từ khi Ban tổ chức trao giải. Ban tổ chức có quyền thay đổi danh sách thành tích vận động viên sau khi hoàn tất thủ tục xem xét, khiếu nại.</li>
            <li>Không tuân thủ các quy định của sự kiện, kể cả những quy định thay đổi, sẽ bị hủy bỏ kết quả, hủy bỏ các giải thưởng và kết quả chính thức.</li>
            <li>Mọi khiếu nại và yêu cầu phúc tra đối với kết quả phải được gửi đến Ban tổ chức để giải quyết. Quyết định của Ban tổ chức có hiệu lực cuối cùng.</li>
            <li>Vận động viên không hoàn thành chặng đua theo thời gian giới hạn (cut-off time) và thời gian theo từng mốc do Ban tổ chức quy định sẽ được đánh dấu là “DNF - Did not finish" trên trang kết quả của Ban tổ chức. Những vận động viên này sẽ không được nhận vật phẩm hoàn thành của Ban tổ chức.</li>
        </ul>
    </ol>
    
    <div class="font-bold">V. THỦ TỤC NHẬN BIB</div>
    <ol class="list-decimal pl-5 py-2">
        <li>Thời gian nhận số đeo: Ngày 08/06/2024</li>
        <li>Địa điểm nhận BIB: Quảng Trường Bình Minh, Thị Xã Cửa Lò - Tỉnh Nghệ An</li>
        <div>Tất cả vận động viên phải nhận các vật dụng thi đấu bao gồm BIB – số báo danh, thiết bị đo thời gian (chiptime), và các dụng cụ khác hỗ trợ cho cuộc đua. Thông tin chi tiết về thời gian, địa điểm và các vật dụng được nhận đăng tải trên trang web và Fanpage chính thức của sự kiện. Nếu vận động viên không thể đến nhận trực tiếp các vật dụng thi đấu thì có thể ủy quyền cho một người khác đến nhận theo hướng dẫn trên trang web của sự kiện. Ban Tổ chức có quyền từ chối kết quả thi đấu nếu phát hiện Vận Động Viên sử dụng các thiết bị đo thời gian không đúng với thiết bị do Ban Tổ chức cung cấp.</div>
        <li>VĐV cần lưu ý:</li>
        <ul class="list-disc pl-5 py-2">
            <li>Vận động viên phải cung cấp giấy tờ tùy thân có ảnh và email có chứa mã QR code (hoặc một dãy mã số assign bib) dưới dạng điện tử hoặc bản giấy khi đến nhận race-kit để xác thực người đăng ký và số BIB tương ứng.</li>
            <li>Vận động viên có trách nhiệm đảm bảo thiết bị đo thời gian gắn với số BIB được đeo đúng cách trong suốt cuộc đua.</li>
            <li>Vận động viên bị phát hiện là đeo nhiều hơn 1 thiết bị đo thời gian trong suốt thời gian thi đấu sẽ bị loại và kết quả thi đấu sẽ không được công nhận.</li>
            <li>Thú cưng, xe đạp, ván trượt, xe đẩy, giày dép lắp con lăn hoặc các vật dụng khác có bánh xe đều không được phép sử dụng hay mang theo trên đường đua ngoại trừ những phương tiện hoặc xe cứu thương của Ban tổ chức.</li>
            <li>Vận động viên sẽ không được nhận race-kit sau thời gian quy định. Ban tổ chức không chịu trách nhiệm cho mọi mất mát, hư hỏng hay thất lạc do vận động viên gây ra dưới bất kỳ hình thức nào. Những vật dụng không được nhận trong thời gian trên sẽ do Ban tổ chức toàn quyền xử lý.</li>
            <li>Kích cỡ áo chạy của vận động viên đã đăng ký là không thay đổi.</li>
        </ul>
    </ol>
    
    <div class="text-center font-bold text-lg">BAN TỔ CHỨC GIẢI</div>
</div>
`;

export const REGULATION_FORM_EN = `
<div>
<div class="text-center font-bold text-lg">RULES OF PARTICIPATION</div>
<div class="font-bold">I. COMPETITION ROUTE CONTENT</div>
<ol class="list-decimal pl-5 py-2">
<li>Competition content</li>
<div>Individual competition in 04 events (distances): 5km, 10km, 21.1km and 42,195km.</div>

<li>Competition format</li>
<div>All athletes gather and start at the same time for each event, and must complete their competition within the time limit corresponding to the distance participated.</div>

<li>How to calculate the solution</li>
<div>
<ul class="list-disc pl-5 py-2">
<li>Final: First, second, third for both men and women of all distances.</li>
<li>In the 42.195km event, any athlete who wins the intermediate prize will not receive an age group prize.</li>
<li>The 42.195km event has prizes for 4 age groups</li>
<li>Under 30 first, second, third for men and women</li>
<li>From 31 - 39 first, second, third for men and women</li>
<li>From 40 - 49 first, second, third, male and female</li>
<li>From 50 and over, first, second, third for men and women (first in age group will receive an additional pair of NIKE Pegasus 40 shoes from popular sponsor TaKuDa)</li>
</ul>
</div>
</ol>


<div class="font-bold">II. GENERAL PROVISIONS</div>
<ol class="list-decimal pl-5 py-2">
<li>Athletes must comply with the regulations, instructions and coordination of the Organizing Committee, Volunteers, Medical Staff, and Traffic Police. Must be respectful and gentle with fellow participants, Organizing Committee members, and spectators before, during and after the swing.</li>
<li>Medical staff appointed by the Organizing Committee can examine any athlete showing signs of poor health. In case the medical staff decides that the athlete should not continue the race, the Organizing Committee has the right to remove the athlete from the event area.</li>
<li>The athlete has the responsibility and obligation to pay for all and any medical expenses incurred as a result of training and/or participation in the event, including ambulance transport, hospital stays hospitals, doctors, pharmaceuticals and services.</li>
<li>Athletes need to contact the emergency numbers on the BIB if they encounter problems on the run and need to go to the nearest water point for assistance when they want to stop the race.</li>
<li>The use of foul language, profanity, quarrels or unsportsmanlike behavior towards other Athletes, race coordinators, medical staff, traffic enforcers, team Volunteers, Organizing Committee members, or spectators are not permitted before, during or after the race and will result in disqualification.</li>
<li>Environmental protection is an important part of the race, littering and destroying the environment are strictly prohibited during the competition. Violations will result in disqualification.</li>
<li>Athletes are not allowed to use stimulants, drugs or other substances that increase feelings of excitement during the race.</li>
<li>Athletes are given numbers to wear: Must wear the number on their chest before starting to create the best conditions for the organizers and machines to perform their tasks.</li>
<li>Athletes participating in the tournament well comply with the regulations of the tournament regulations and Vietnamese athletics laws.</li>
<li>During the competition, do not ask for help from outside individuals or organizations. If discovered, the competition results will not be recognized.</li>
<li>During the running process, do not jostle, push, or trample each other. Athletes are not allowed to use other people's numbers to compete. If discovered, the results will be canceled and they will be banned from competing in the following years. </li>
</ol>


<div class="font-bold">III. COMPETITION REGISTRATION AND FEES</div>
<ol class="list-decimal pl-5 py-2">
<li>Registration location</li>
<li>Registration period</li>
<li>BIB receipt time (June 8, 2024)</li>
<li>Registration fee (Updated on registration portal)</li>
<li>Registration age</li>
<div>
<ul class="list-disc pl-5 py-2">
<li>All athletes participating in the 42KM distance: Must be 16 years old or older as of the date of participation.</li>
<li>All athletes participating in the 21KM distance: Must be 14 years old or older as of the date of participation.</li>
<li>All athletes participating in the 10KM distance: Must be 10 years old or older as of the date of participation.</li>
<li>All athletes participating in the 5KM distance: Must be 3 years old or older as of the date of participation.</li>
<li>All runners 10 years old and under participating in the 5km distance should have a guardian running with them.</li>
</ul>
</div>

</ol>
    
    
<div class="font-bold">IV. REWARDS - DISCIPLINE</div>
<ol class="list-decimal pl-5 py-2">
<li>Praise</li>
<div>Athletes receive cash prizes and gifts</div>
<div>Final: First, second, third, both men and women, all distances.</div>
<ul class="list-disc pl-5 py-2">
<li>Age group under 30 years old is first, second, third</li>
<li>Age group from 30 - 39</li>
<li>Age group 40- 49</li>
<li>The 50+ age group most men and women are rewarded with additional pairs of sponsor shoes</li>
</ul>
<li>Discipline</li>
<div>For athletes who violate the provisions of the law and regulations, depending on the severity, the organizing committee will take appropriate disciplinary measures, the highest being cancellation of competition results and ban from competition in the following years. </div>
<li>Complaints</li>
<div>Athletes have the right to complain in writing to the organizing committee and are responsible for the content of that complaint. Complaints about personnel must be made before the match, complaints about expertise must be made no later than 10 minutes after the announcement of the match results by the referee board.</div>
<li>Regulations on competition results and award rankings</li>
<ul class="list-disc pl-5 py-2">
<li>The temporary ranking tags given to athletes at the finish line are only used to determine the actual top rankings at the end of the race. Then the Organizing Committee and the referee team will compare the necessary information with the camera at the finish line to be able to summarize the most accurate final results before announcing it at the awards ceremony.</li>
<li>The competition results of each athlete are recorded by a time measuring device (chiptime) mounted on the BIB provided by the Organizing Committee. Chiptime is calculated from the moment the athlete crosses the chip mat at the starting line and ends at the finish line.</li>
<li>Gun time (the time calculated from the start signal until the athlete runs across the chip mat at the finish line) will be used to rank and award prizes, which is the actual finishing order of the athlete. member for competition registration content.</li>
<li>Athletes will not be recognized for finishing if they exceed the cut-off time as announced by the Organizing Committee.</li>
<li>The organizing committee will disqualify or cancel the competition results if it detects athletes cheating.</li>
<li>The organizing committee has full authority to decide on the ranking results, awarding prizes and competition results. The decision of the Organizing Committee is final. All complaints about first, second, and third prizes must be made within 3 hours of the Organizing Committee awarding the prizes. The organizing committee has the right to change the list of athlete achievements after completing the review and appeal procedures.</li>
<li>Failure to comply with event regulations, including changes to regulations, will result in cancellation of results, cancellation of prizes and official results.</li>
<li>All complaints and requests for review of results must be sent to the Organizing Committee for resolution. The decision of the Organizing Committee has final effect.</li>
<li>Athletes who do not complete the race according to the cut-off time and time according to each milestone prescribed by the Organizing Committee will be marked as "DNF - Did not finish" on the results page of the Organizing Committee. These athletes will not receive completed items from the Organizing Committee.</li>
</ul>
</ol>
    
<div class="font-bold">V. PROCEDURES FOR RECEIVING BIB</div>
<ol class="list-decimal pl-5 py-2">
<li>Time to receive wearing numbers: June 8, 2024</li>
<li>BIB pick-up location: Binh Minh Square, Cua Lo Town - Nghe An Province</li>
<div>All athletes must receive competition items including BIB - registration number, time measuring device (chiptime), and other equipment to support the race. Detailed information about time, location and accepted items will be posted on the event's official website and Fanpage. If the athlete cannot pick up the competition items in person, he or she can authorize another person to pick them up according to the instructions on the event website. The Organizing Committee has the right to reject competition results if it detects that Athletes are using timing devices inconsistent with those provided by the Organizing Committee.</div>
<li>Athletes should note:</li>
<ul class="list-disc pl-5 py-2">
<li>Athletes must provide photo identification and an email containing a QR code (or a series of assigned bib codes) in electronic or paper form when picking up the race-kit to authenticate the registrant. and corresponding BIB number.</li>
<li>It is the athlete's responsibility to ensure that the timing device associated with the BIB number is worn correctly throughout the race.</li>
<li>Athletes found to be wearing more than one timing device during the competition will be disqualified and their competition results will not be recognized.</li>
<li>Pets, bicycles, skateboards, strollers, roller shoes or other wheeled items are not permitted to be used or carried on the track except for official vehicles or ambulances. Organizing Committee.</li>
<li>Athletes will not receive race kits after the specified time. The Organizing Committee is not responsible for any loss, damage or loss caused by athletes in any way. Items not received within the above time will be handled by the Organizing Committee.</li>
<li>Registered athletes' running shirt sizes are unchanged.</li>
</ul>
</ol>
    
<div class="text-center font-bold text-lg">TOURNAMENT ORGANIZING COMMITTEE</div>
</div>
`;
