import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useSearchParams } from "react-router-dom";
import { INFORMATION } from "../routes/path";
import { getDisclamerForm } from "../services";
import { Button } from "antd";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import { DownloadOutlined } from "@ant-design/icons";

const EwaiverOfRunnerPage = () => {
  const { t } = useTranslation();
  const [runnerInfo, setRunnerInfo] = useState();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key")?.replaceAll(" ", "+");
  const navigate = useNavigate();
  const pdfRef = useRef();

  useEffect(() => {
    const _getDisclamerForm = async () => {
      const res = await getDisclamerForm({
        key,
      });

      setRunnerInfo(res?.[0]);
    };

    if (key) {
      _getDisclamerForm();
    }
  }, [key]);

  useEffect(() => {
    if (runnerInfo) {
      if (!runnerInfo?.runnerOrderDetailEwaiver) {
        navigate(`/${INFORMATION}?key=${key}`);
      }
    }
  }, [runnerInfo]);

  const handleDownloadPdf = useReactToPrint({
    content: () => pdfRef.current,
    pageStyle: `@media print {
      @page {
        margin: 10;
      }
    }`,
  });

  return (
    <>
      <div className="bg-[#6B7280]">
        <div className="w-full xl:w-[70%]  m-auto bg-[#fff]">
          <div
            ref={pdfRef}
            dangerouslySetInnerHTML={{
              __html: runnerInfo?.runnerOrderDetailEwaiver,
            }}
          />
        </div>
        <div className="p-2 text-center fixed bottom-3 w-full">
          <Button
            onClick={handleDownloadPdf}
            type="primary"
            icon={<DownloadOutlined />}
            iconPosition="start"
          >
            Print Ewaiver
          </Button>
        </div>
      </div>
    </>
  );
};

export default EwaiverOfRunnerPage;
