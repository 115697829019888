import Axios from "../configs/axios";

export const getDisclamerForm = async (payload) => {
  const res = await Axios.post(`/public/get_runner_order_e_waiver`, payload);
  return res?.data;
};

export const signDisclamerOfRunner = async (payload) => {
  const res = await Axios.post(`/public/runner_order_e_waiver`, payload);
  return res?.data;
};

export const uploadImageToCloud = async (payload) => {
  const res = await Axios.post(`/public/upload-image`, payload);
  return res.data.result;
};

export const updatePresentative = async (payload) => {
  const res = await Axios.put(`/public/runner_order_representative`, payload);
  return res.data.result;
};
