import moment from "moment";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { getDisclamerForm } from "../services";
import { REGULATION_FORM_EN, REGULATION_FORM_VI } from "../constants";

const RegulationPage = () => {
  const { t } = useTranslation();
  const [runnerInfo, setRunnerInfo] = useState();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key")?.replaceAll(" ", "+");
  const language = localStorage.getItem("lang");

  useEffect(() => {
    const _getDisclamerForm = async () => {
      const res = await getDisclamerForm({
        key,
      });
      setRunnerInfo(res?.[0]);
    };

    if (key) {
      _getDisclamerForm();
    }
  }, [key]);

  return (
    <div>
      <div className="w-full absolute left-0 bg-[url('/public/bg-marathon.jpg')] bg-cover bg-left-bottom h-[5rem] md:h-[10rem] flex flex-col justify-center">
        <div className="text-white text-center text-[16px] sm:text-[24px] md:text-[36px] font-bold">
          {runnerInfo?.marathonName}
        </div>
        <div className="text-white text-center font-semibold">
          {moment(runnerInfo?.marathonStartTime).format("DD.MM.YYYY")}
        </div>
      </div>

      <div className="px-4 pt-[6rem] md:pt-[11rem] mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-0 lg:pt-[12rem]">
        <div className="shadow-none md:shadow-2xl p-0 md:p-5">
          <div
            dangerouslySetInnerHTML={{
              __html:
                language === "en" ? REGULATION_FORM_EN : REGULATION_FORM_VI,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default RegulationPage;
