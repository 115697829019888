export function convertBase64ToBlob(base64Image) {
  const binaryImageData = atob(base64Image.split(",")[1]);
  const arrayBuffer = new ArrayBuffer(binaryImageData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  for (let i = 0; i < binaryImageData.length; i++) {
    uint8Array[i] = binaryImageData.charCodeAt(i);
  }
  const blob = new Blob([uint8Array], { type: "image/png" });
  return blob;
}
