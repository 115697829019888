import { CheckOutlined, MinusOutlined } from "@ant-design/icons";
import moment from "moment";
import { useEffect, useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import { EWAIVER, FINISH, INFORMATION } from "../routes/path";
import { getDisclamerForm } from "../services";
import { useTranslation } from "react-i18next";

export const Step = ({ children }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key")?.replaceAll(" ", "+");
  const [runnerInfo, setRunnerInfo] = useState();

  useEffect(() => {
    const _getDisclamerForm = async () => {
      const res = await getDisclamerForm({
        key,
      });

      setRunnerInfo(res?.[0]);
    };

    if (key) {
      _getDisclamerForm();
    }
  }, [key]);

  const renderBoxBgStep = (fill, textColor, text) => {
    return (
      <div className="relative">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="274"
          height="60"
          viewBox="0 0 274 60"
          fill="none"
        >
          <g filter="url(#filter0_d_8715_5070)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M257.152 3.01544C256.439 1.76914 255.114 1 253.679 1H6.89263C3.82134 1 1.89587 4.31793 3.41966 6.98456L14.8659 27.0154C15.5686 28.2452 15.5686 29.7548 14.8659 30.9846L3.41966 51.0154C1.89587 53.6821 3.82134 57 6.89263 57H253.679C255.114 57 256.439 56.2309 257.152 54.9846L270.866 30.9846C271.569 29.7548 271.569 28.2452 270.866 27.0154L257.152 3.01544Z"
              fill={fill}
            />
          </g>
          <defs>
            <filter
              id="filter0_d_8715_5070"
              x="0.886719"
              y="0"
              width="272.506"
              height="60"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="1" />
              <feGaussianBlur stdDeviation="1" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0.215686 0 0 0 0 0.254902 0 0 0 0 0.317647 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_8715_5070"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_8715_5070"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
        <div
          className={`absolute top-[1.1rem] left-[2.2rem] ${textColor} font-bold`}
        >
          {text}
        </div>
      </div>
    );
  };

  const renderStep = () => {
    return (
      <div className="flex justify-center text-center mb-6">
        {renderBoxBgStep(
          location.pathname?.includes(INFORMATION) ||
            location.pathname?.includes(EWAIVER) ||
            location.pathname?.includes(FINISH)
            ? "#202020"
            : "#C4C4C4",
          location.pathname?.includes(INFORMATION) ||
            location.pathname?.includes(EWAIVER) ||
            location.pathname?.includes(FINISH)
            ? "text-[#D1F700]"
            : "text-[#000000]",
          t("runner_info")
        )}

        {renderBoxBgStep(
          location.pathname?.includes(FINISH) ||
            location.pathname?.includes(EWAIVER)
            ? "#202020"
            : "#C4C4C4",
          location.pathname?.includes(FINISH) ||
            location.pathname?.includes(EWAIVER)
            ? "text-[#D1F700]"
            : "text-[#000000]",
          t("sign_disclaimer")
        )}

        {renderBoxBgStep(
          location.pathname?.includes(FINISH) ? "#202020" : "#C4C4C4",
          location.pathname?.includes(FINISH)
            ? "text-[#D1F700]"
            : "text-[#000000]",
          t("finish")
        )}
      </div>
    );
  };

  const renderStepDone = () => {
    return (
      <CheckOutlined className="text-lg text-[#D1F700] border p-1 md:border-2 md:p-2 bg-[#202020] border-black rounded-3xl" />
    );
  };

  const renderStepInProcess = () => {
    return (
      <MinusOutlined className="text-lg text-[#000] border p-1 md:p-2 bg-[#fff] rounded-3xl border-black md:border-2" />
    );
  };

  const renderStepInActive = () => {
    return (
      <div className="text-lg text-[#000] p-[0.8rem] md:p-[1.1rem] border bg-[#fff] rounded-3xl border-[#D1D5DB] md:border-2" />
    );
  };

  const renderStepOne = () => {
    if (location.pathname?.includes(INFORMATION)) {
      return renderStepInProcess();
    }

    if (
      location.pathname?.includes(EWAIVER) ||
      location.pathname?.includes(FINISH)
    ) {
      return renderStepDone();
    }
  };

  const renderStepTwo = () => {
    if (location.pathname?.includes(INFORMATION)) {
      return renderStepInActive();
    }

    if (location.pathname?.includes(EWAIVER)) {
      return renderStepInProcess();
    }

    if (location.pathname?.includes(FINISH)) {
      return renderStepDone();
    }
  };

  const renderStepThree = () => {
    if (
      location.pathname?.includes(INFORMATION) ||
      location.pathname?.includes(EWAIVER)
    ) {
      return renderStepInActive();
    }

    if (location.pathname?.includes(FINISH)) {
      return renderStepDone();
    }
  };

  const renderStepMobile = () => {
    return (
      <div className="flex items-center w-full mb-2">
        {renderStepOne()}
        <div className="w-7 text-black border flex-1" />
        {renderStepTwo()}
        <div className="w-7 text-black border flex-1" />
        {renderStepThree()}
      </div>
    );
  };

  return (
    <>
      <div className="w-full absolute left-0 bg-[url('/public/bg-marathon.jpg')] bg-cover bg-left-bottom h-[5rem] md:h-[10rem] flex flex-col justify-center">
        <div className="absolute flex items-center justify-center flex-col inset-0 bg-black bg-opacity-50">
          <div className=" text-center text-[16px] sm:text-[24px] md:text-[36px] font-bold text-white">
            {runnerInfo?.marathonName}
          </div>
          <div className="text-white text-center font-semibold">
            {moment(runnerInfo?.marathonStartTime).format("DD.MM.YYYY")}
          </div>
        </div>
      </div>
      <div className="px-4 pt-[6rem] md:pt-[11rem] mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-xl md:px-24 lg:px-0 lg:pt-[12rem]">
        <div className="hidden min-[845px]:block">{renderStep()}</div>
        <div className="block min-[845px]:hidden">{renderStepMobile()}</div>
        {children}
      </div>
    </>
  );
};
