import { BrowserRouter as Router } from "react-router-dom";
import RootRouter from "./routes/routes";
import { ConfigProvider } from "antd";
import { useTranslation } from "react-i18next";
import { useEffect } from "react";

function App() {
  const { i18n } = useTranslation();
  const language = localStorage.getItem("lang");

  useEffect(() => {
    if (!language) {
      i18n.changeLanguage("vi");
      localStorage.setItem("lang", "vi");
    } else {
      i18n.changeLanguage(language);
    }
  }, []);

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: "#202020",
        },
      }}
    >
      <Router>
        <RootRouter />
      </Router>
    </ConfigProvider>
  );
}

export default App;
