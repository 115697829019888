import { Button, Layout as LayoutAnt } from "antd";
import React from "react";
import { useTranslation } from "react-i18next";
import { Outlet } from "react-router-dom";
const { Header, Content, Footer } = LayoutAnt;

const Layout = () => {
  const { i18n } = useTranslation();
  const language = localStorage.getItem("lang");

  return (
    <LayoutAnt className="h-full bg-white">
      <Header className="sticky top-0 w-full flex items-center justify-between bg-[#202020] z-10 p-3 sm:p-5 md:p-8 lg:px-10">
        <img
          src="/racetime-logo.png"
          alt="Racetime logo"
          className="w-[5rem] md:w-[10rem]"
        />
        <Button
          type="text"
          size="small"
          className="text-black font-semibold hover:text-black flex items-center gap-2"
          onClick={() => {
            if (language === "vi") {
              i18n.changeLanguage("en");
              localStorage.setItem("lang", "en");
            } else {
              i18n.changeLanguage("vi");
              localStorage.setItem("lang", "vi");
            }
          }}
        >
          {language === "en" ? (
            <img src="/vietnam-flag.png" alt="Vietnamese flag" />
          ) : (
            <img src="/united-states-flag.png" alt="United States flag" />
          )}
          {/* {language === "en" ? "VIETNAMESE" : "ENGLISH"} */}
        </Button>
      </Header>
      <Content className="h-full">
        <div
          className="m-auto w-full md:w-[90%] mb-5"
          style={{ minHeight: "93vh" }}
        >
          <Outlet />
        </div>
        <Footer className="text-center bg-[#202020] p-3 static bottom-0 w-full text-white text-xs md:text-md">
          RACETIME ©{new Date().getFullYear()} All rights reserved
        </Footer>
      </Content>
    </LayoutAnt>
  );
};

export default Layout;
