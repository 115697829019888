import { Divider } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Step } from "../components/step";
import { GENDER } from "../constants";
import { INFORMATION } from "../routes/path";
import { getDisclamerForm } from "../services";
import { useTranslation } from "react-i18next";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import moment from "moment";

const FinishPage = () => {
  const { t } = useTranslation();
  const [runnerInfo, setRunnerInfo] = useState();
  const [searchParams] = useSearchParams();
  const key = searchParams.get("key")?.replaceAll(" ", "+");
  const navigate = useNavigate();

  useEffect(() => {
    const _getDisclamerForm = async () => {
      const res = await getDisclamerForm({
        key,
      });

      setRunnerInfo(res?.[0]);
    };

    if (key) {
      _getDisclamerForm();
    }
  }, [key]);

  const renderRunnerInfoItem = (label, value) => {
    return (
      <div className="mb-3">
        <div className="text-[gray]">{label}</div>
        <div className="font-bold">{value}</div>
      </div>
    );
  };

  useEffect(() => {
    if (runnerInfo) {
      if (!runnerInfo?.runnerOrderDetailEwaiver) {
        navigate(`/${INFORMATION}?key=${key}`);
      }
    }
  }, [runnerInfo]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      {runnerInfo && (
        <Step>
          <div className="shadow-none md:shadow-2xl p-0 md:p-5">
            <div className="flex flex-col items-center gap-2">
              <CheckCircleFilled className="text-success text-[36px] md:text-[48px] text-[#357a38]" />
              <div className="text-[18px] sm:text-[24px] font-semibold text-center">
                {t("send_disclaimer_success")}
              </div>

              <div className="text-center">{t("disclaimer_success_noti")}</div>
            </div>

            <Divider className="mt-2" />

            <div className="mb-2 text-[#6B7280] font-bold">
              {t("runner_info")}
            </div>

            <div className="block sm:grid sm:grid-cols-2">
              <div>
                <div>
                  {renderRunnerInfoItem(t("name"), runnerInfo?.runnerOrderName)}
                  {renderRunnerInfoItem(
                    t("distance"),
                    runnerInfo?.runningDistanceName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    "Bib",
                    runnerInfo?.runnerOrderDetailBib || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("name_of_bib"),
                    runnerInfo?.runnerOrderDetailBibName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("dob"),
                    runnerInfo?.runnerOrderBirthday
                      ? moment(
                          runnerInfo?.runnerOrderBirthday,
                          "YYYY-MM-DD"
                        ).format("DD/MM/YYYY")
                      : t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("gender"),
                    GENDER[runnerInfo?.runnerOrderGender] || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("passport"),
                    runnerInfo?.runnerOrderPassport || t("none")
                  )}
                </div>
              </div>
              <div>
                <div>
                  {renderRunnerInfoItem(
                    t("phone"),
                    runnerInfo?.runnerOrderPhone || t("none")
                  )}
                  {renderRunnerInfoItem(
                    "Email",
                    runnerInfo?.runnerOrderEmail || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("address"),
                    runnerInfo?.runnerOrderAddress || t("none")
                  )}
                  {renderRunnerInfoItem(
                    "Racekit",
                    runnerInfo?.runnerRaceKit || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("size_of_racekit"),
                    runnerInfo?.runnerOrderDetailShirtSize || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("emergency_fullname"),
                    runnerInfo?.runnerOrderEmergencyFullName || t("none")
                  )}
                  {renderRunnerInfoItem(
                    t("emergency_phone"),
                    runnerInfo?.runnerOrderEmergencyPhone || t("none")
                  )}
                </div>
              </div>
            </div>

            <Divider className="mt-2" />

            <div className="mb-2 text-[#6B7280] font-bold">
              {t("bib_receiver")}
            </div>

            {runnerInfo?.runnerOrderRepresentativeType === "YOURSELF" ? (
              <div className="font-bold">{t("yourself_receive")}</div>
            ) : (
              <>
                <div className="font-bold mb-2">{t("bib_recei_orther")}</div>
                <div className="block sm:grid sm:grid-cols-2">
                  <div>
                    <div>
                      {renderRunnerInfoItem(
                        t("full_name_of_authorized_person"),
                        runnerInfo?.runnerOrderRepresentativeFullName
                      )}
                      {renderRunnerInfoItem(
                        t("passport"),
                        runnerInfo?.runnerOrderRepresentativeCCCD
                      )}
                    </div>
                  </div>
                  <div>
                    <div>
                      {renderRunnerInfoItem(
                        "Email",
                        runnerInfo?.runnerOrderRepresentativeEmail
                      )}
                      {renderRunnerInfoItem(
                        t("phone"),
                        runnerInfo?.runnerOrderRepresentativePhone
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}

            <div className="text-[blue] font-bold">
              {t("qrcode_text")}{" "}
              {runnerInfo?.runnerOrderRepresentativeType === "YOURSELF"
                ? t("attend")
                : t("authorized")}
              , {t("email_address")}:{" "}
              <span className="underline">
                {runnerInfo?.runnerOrderRepresentativeType === "YOURSELF"
                  ? runnerInfo?.runnerOrderEmail
                  : runnerInfo?.runnerOrderRepresentativeEmail}
              </span>
            </div>
            <div className="text-[#6B7280]">{t("qrcode_warning")}</div>
          </div>
        </Step>
      )}
    </div>
  );
};

export default FinishPage;
