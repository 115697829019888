import { Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import InformationPage from "../pages/information.page";
import EwaiverPage from "../pages/ewaiver.page";
import {
  EWAIVER,
  EWAIVER_RUNNER,
  FINISH,
  INFORMATION,
  REGULATIONS,
} from "./path";
import FinishPage from "../pages/finish.page";
import Layout from "../layout/layout";
import EwaiverOfRunnerPage from "../pages/ewaiver-runner.page";
import RegulationPage from "../pages/regulations.page";

const RootRouter = () => {
  return (
    <Suspense>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path={`/${INFORMATION}`} element={<InformationPage />} />
          <Route path={`/${EWAIVER}`} element={<EwaiverPage />} />
          <Route path={`/${FINISH}`} element={<FinishPage />} />
          <Route path={`/${REGULATIONS}`} element={<RegulationPage />} />
        </Route>

        <Route
          path={`/${EWAIVER_RUNNER}`}
          element={<EwaiverOfRunnerPage />}
        ></Route>
      </Routes>
    </Suspense>
  );
};

export default RootRouter;
