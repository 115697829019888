import axios from "axios";

const Axios = axios;

Axios.interceptors.request.use((config) => {
  return config;
});
Axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    console.log(error);
    if (error && error.response && error.response.status === 401)
      return Promise.reject(error);
  }
);

Axios.defaults.baseURL = process.env.REACT_APP_ENPOINT;

export default Axios;
